import React from "react";

const Maintenance = (props) => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img alt="image" src="/playground_assets/vc.png" />
      <p>Coming soon!</p>
    </div>
  );
};

export default Maintenance;
