import React from "react";

import PropTypes from "prop-types";

import "./branding.css";

const Branding = (props) => {
  return (
    <div className="branding-branding">
      <span className="branding-text">{props.text}</span>
    </div>
  );
};

Branding.defaultProps = {
  text: "VirtueCode</>",
};

Branding.propTypes = {
  text: PropTypes.string,
  text1: PropTypes.string,
};

export default Branding;
