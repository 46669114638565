import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./style.css";
import Home from "./views/home";
import Maintenance from "./views/maintenance";

const MAINTENANCE_MODE = true;

const App = () => {
  return (
    <Router>
      <div>
        <Route
          component={MAINTENANCE_MODE ? Maintenance : Home}
          exact
          path="/"
        />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
